import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import programmePdf from "../images/programme-grenoble.pdf";
import flyer from "../images/flyer-grenoble.jpg";
import inscription from "../images/inscription-grenoble.jpg";
import Icon from "./Icon";

const Contact = () => {
  const { t } = useTranslation();

  if (isMobile) {
    return (
      <div className="mb-3">
        <div className="row ">
          <div className="col">
            {t("sas_aventures.programme")}
            <a href={programmePdf} target="_blank" rel="noreferrer">
              {t("sas_aventures.download")}
           </a>
          </div>
        </div>
        <div className="row ">
          <div className="col">
            {t("sas_aventures.form")}
            <a href={inscription} target="_blank" rel="noreferrer">
              {t("sas_aventures.download")}
           </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {t("sas_aventures.email")}
            <a href="mailto:contact@coachinactions.com">{t("contact.email")}</a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {t("sas_aventures.phone")}
            <Icon name="phone" />
            <a href="tel:0680662919">{t("contact.phone")}</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.programme")}</h4>
        </div>
        <div className="col">
          <h5>
            <a href={programmePdf} target="_blank" rel="noreferrer">
              {t("sas_aventures.download")}
            </a>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.form")}</h4>
        </div>
        <div className="col">
          <h5>
            <a href={inscription} target="_blank" rel="noreferrer">
              {t("sas_aventures.download")}
            </a>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.email")}</h4>
        </div>
        <div className="col">
          <h5>
            <a href="mailto:contact@coachinactions.com">{t("contact.email")}</a>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col text-md-right">
          <h4>{t("sas_aventures.phone")}</h4>
        </div>
        <div className="col">
          <h5>
            <Icon name="phone" />
            <a href="tel:0680662919">{t("contact.phone")}</a>
          </h5>
        </div>
      </div>
    </>
  );
};

const SASAventures = () => {
  return (
    <div className="container justify-content-md-center">
      <Contact />
      <div className="row container text-center m-4">
        <div className="col">
        Retrouvez mon interview avec radio RFC Isère, dans le cadre de l'émission Parole de Leader, <a href=" https://www.linkedin.com/posts/club-radio-rcf-is%C3%A8re_avec-alain-endezoumou-avancer-en-profondeur-activity-7307842047447568385-GOaT?utm_source=share&utm_medium=member_android&rcm=ACoAAAQnXd4B-x-TK3W-KfFPln-5TYbcvg0DAPc" target="_blank" rel="noreferrer">en cliquant ici</a>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <img
            src={flyer}
            alt="flyer"
            className="img-fluid rounded mb-4 w-75 d-block mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default SASAventures;
